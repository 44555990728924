* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;

	background-color: #fff;
}

html.dark-mode {
	background-color: #000;
}

button:focus {
	outline: 1px solid #777 !important;
}

.lead {
	font-weight: 400;
}

.bg-danger {
	background-color: var(--primary-color) !important;
}

.Header {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	height: 100vh;

	text-align: center;
	font-family: Montserrat, sans-serif;
}

.Header h1 {
	position: absolute;
	padding-bottom: 4rem;

	text-align: center;
	font-size: 5.5rem;

	color: #000;
}

.Header p {
	margin-top: 125px;
}

.particle {
	position: absolute!;
	margin: 0 !important;
	margin-top: -100vh !important;
}

.underline {
	text-decoration: underline;
	text-decoration-color: var(--primary-color);
}

.skills {
	padding: 3%;
}

.progress-block {
	padding: 10px 0;
}

.progress-block h2 {
	font-size: x-large;
}

.dark-mode {
	filter: invert(1) hue-rotate(180deg);

	-webkit-filter: invert(1) hue-rotate(180deg);
	-moz-filter: invert(1) hue-rotate(180deg);
}

.inverse-dark {
	filter: invert(1) hue-rotate(180deg);

	-webkit-filter: invert(1) hue-rotate(180deg);
	-moz-filter: invert(1) hue-rotate(180deg);
}

.effect2 {
	position: relative;
	width: 70%;
	padding: 5%;
	margin: 10% auto;

	border-bottom: solid 1px #777 !important;
	background: #fff;
}

.effect2:after,
.effect2:before {
	z-index: -1;
	position: absolute;
	content: "";
	bottom: 15px;
	left: 10px;
	width: 50%;
	top: 80%;
	max-width: 300px;
	background: #777;
	-webkit-box-shadow: 0 15px 10px #777;
	-moz-box-shadow: 0 15px 10px #777;
	box-shadow: 0 15px 10px #777;
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	transform: rotate(-3deg);
}

.effect2:after {
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	transform: rotate(3deg);
	right: 10px;
	left: auto;
}

.Photo {
	background-color: #fff;
	background-size: contain;
	background-repeat: no-repeat;
}

.Photo.inverse-dark {
	background-color: #000;
}

.about-text {
	width: 80%;
	padding: 2%;
}

.red-line {
	color: var(--primary-color);
	font-weight: 500;
}

.about-title-box {
	width: 50%;
	box-sizing: border-box;

	margin-bottom: 15px;
	padding-bottom: 5px;
	border-bottom: solid 2px var(--primary-color);
}

.skill__square {
	display: inline;
	margin: 15px 0 !important;
}

.skill__square i {
	height: 20px !important;
	font-size: 50px !important;
	color: #777 !important;
}

.skill__square svg {
	width: 35px !important;
	min-height: 20px !important;
	padding: 0;
	margin: 0;
	color: #777 !important;
}

.skill__square h6 {
	text-align: center;
	margin: auto;
	font-size: 1em;
	padding-top: 5px;
}

.skill__square i:hover,
.skill__square svg:hover {
	color: #ff4753 !important;
}

.third {
	width: 75%;
	margin: 10% auto;
	align-items: center;
	padding: 3%;
	justify-content: center;
}

.project-card {
	padding: 15px;
	margin-top: 15px;
	box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
	border: 0.5px solid #eee;
}

.project-card h2 {
	color: var(--primary-color);
	margin-top: 10px;
	margin-left: 3%;
	font-weight: 500;
}

.project-card h2:hover {
	color: var(--hover-color);
	text-decoration: none;
}

.project-card h3 {
	text-align: left;
	font-size: 1rem;
	margin-left: 3%;
}

.image-project {
	height: 300px;
	width: auto;
	margin-bottom: 20px;
	overflow: hidden;
	cursor: pointer !important;
}

.img-pro {
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.image-project:hover .img-pro {
	transform: scale(1.2);
}

.pp-head {
	text-align: center;
}

.pp-head-line {
	box-sizing: border-box;
	width: 30%;
	padding-bottom: 5px;
	border-bottom: solid 2px var(--primary-color);
	margin-bottom: 15px;
}

.pp-text {
	font-size: 1.5rem;
}

.parallax {
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.git-form {
	width: 1160px;
	box-sizing: border-box;
	display: block;
	margin: 3rem 3rem 0 3rem;
	background-color: #fff;
	max-height: 80vh;
}

.git-head-div {
	box-sizing: border-box;
	padding-top: 5%;
	width: 30%;
	border-bottom: 3px solid var(--primary-color);
	color: var(--primary-color);
	font-weight: 500;
	text-align: center;
}

.git-cont {
	display: inline-block;
	padding-bottom: 2rem;
}

.git-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 45%;
	margin: 3rem 20rem 3rem 20rem;
}

.half {
	display: inline-block;
	vertical-align: top;
}

.half form input,
textarea {
	width: 100%;
	margin: 10px;
	padding: 10px;
	border-radius: 5px;
	outline: 0;
	box-shadow: none;
	border: 1px solid #777;
}

.half form textarea {
	padding-bottom: 45px;
}

.half form input:focus,
textarea:focus {
	border-radius: 5px;
	outline: 0;
	box-shadow: none;
	border: 1px solid var(--primary-color);
}

.half form button {
	padding: 10px 15px;
	font-size: 1rem;
	border-radius: 2rem;
	color: #fff;
	background-color: var(--primary-color);
	box-shadow: none;
	outline: 0;
	border: none;
	margin-top: 20px;
	margin-left: 20px;
}

.half form button:hover {
	background-color: var(--hover-color);
}

.half form {
	padding: 3rem 1rem;
}

.half p {
	padding: 3rem 1rem 0 1rem;
	text-align: center;
}

.inline-button {
	display: inline;
	vertical-align: middle;
	display: flex;
	flex-direction: row;
}

.git-cont .fab {
	vertical-align: bottom;
	padding: 10px;
	font-size: 30px;
	height: 30px;
	text-align: center;
	text-decoration: none;
	color: var(--primary-color);
	transition: 0.5;
}

.git-cont .fab:hover {
	color: var(--hover-color);
}

.git-cont .fas {
	vertical-align: bottom;
	font-size: 40px;
	height: 30px;
	margin: 25px;
	text-align: center;
	text-decoration: none;
	color: #000;
	transition: 0.5;
}

.navbar {
	width: 100%;
	position: fixed;
	z-index: 1001;
	top: 0;
	box-shadow: 0 8px 6px -6px #646464;
}

.nav-link {
	color: #000 !important;
}

.is-current {
	color: var(--primary-color);
	text-decoration: underline;
	text-decoration-style: solid 3px;
	text-decoration-color: var(--primary-color);
}

.Copy {
	color: #fff !important;
	font-weight: 500;
	padding-top: 2%;
}

.project-name {
	text-decoration: none;
}

.gtp {
	position: fixed;
	left: 92%;
	height: 150px;
	top: 82%;
	z-index: 1002;
	cursor: crosshair;
}

.see {
	background-color: var(--primary-color);
	color: #fff;
	border: #000;
	outline: #000;
	position: absolute;
	padding: 5px 15px;
	padding-bottom: 7px;
	border-radius: 3px;
	left: 44%;
	top: 90%;
}

.fa-eye {
	color: #fff;
	font-size: 20px;
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 15px;
	padding-right: 7px;
}

.see:active {
	outline: 0;
}

.see:hover {
	background-color: var(--hover-color);
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	left: 44.5%;
	bottom: 46%;
}

.Header p {
	margin-top: 40px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--primary-color);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: #fff;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #000;
}

input:focus + .slider {
	box-shadow: 0 0 1px #000;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.line-1 {
	position: relative;
	top: 10%;
	width: 12em;
	margin: 0 auto;
	border-right: 2px solid rgba(25, 25, 25, 0.75);
	font-size: 180%;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	transform: translateY(-50%);
	font-family: "Anonymous Pro", monospace !important;
}

.anim-typewriter {
	animation: typewriter 4s steps(27) 1s 1 normal both,
		blinkTextCursor 0.5s steps(27) infinite normal;
}

.parallax {
	background-image: url(../img/Contact.jpg);
}

::-moz-selection {
	color: #fff;
	background: var(--primary-color);
}

::selection {
	color: #fff;
	background: var(--primary-color);
}

@media (max-width: 1300px) {
	.effect2 {
		width: 100%;
	}
}

@media (max-width: 800px) {
	.lead {
		font-weight: 300;
	}

	.Header h1 {
		font-size: 2.5rem;
	}

	.line-1 {
		font-size: 80%;
		top: 5.5%;
	}

	.underline {
		text-decoration-color: (0, 0, 0, 0.1);
	}

	.about-text {
		width: 100%;
		text-align: center;
	}

	.red-line::before {
		bottom: 590px;
	}

	.effect2 {
		width: 100%;
	}

	.skills {
		padding: 10% 5%;
	}

	.third {
		width: 100%;
	}

	.project-card {
		width: 100% !important;
	}

	.image-project {
		width: 100% !important;
	}

	.third {
		padding: 2%;
	}

	.pp-head-line {
		width: 70%;
	}

	.git-form {
		width: 90%;
		padding: 5px;
		padding-bottom: 35px;
	}

	.git-text {
		margin: 3rem 8rem 3rem 12rem;
	}

	.git-head-div {
		width: 75%;
	}

	.switch {
		bottom: 48%;
		left: 35%;
	}

	.half form input,
	textarea {
		margin: 5px 0;
		padding: 10px;
	}

	.half p {
		display: none;
	}

	.gtp {
		left: 80%;
		top: 87%;
		height: 75px;
	}

	.Copy {
		display: none;
	}

	.see {
		margin: 0;
		position: absolute;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 1rem;
	}

	.fa-eye {
		font-size: 20px;
	}

	.half form button {
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 600px) {
	.git-text {
		width: 100%;
		margin: 0rem 0rem 0rem 0rem;
	}
}

.Photo {
	background-image: url("https://media-exp1.licdn.com/dms/image/C5603AQHLco360EVuOQ/profile-displayphoto-shrink_800_800/0/1631977168613?e=1638403200&v=beta&t=9yn2Xvd3XOCihdXoZ-GdE-VIJaGkMtgPaKVRQ63Zo7s");
}

.project1 {
	background-image: url("../img/Project1.png");
}
.project2 {
	background-image: url("../img/Project2.png");
}
.project3 {
	background-image: url("../img/Project3.png");
}
.project4 {
	background-image: url("../img/Project4.png");
}
.project5 {
	background-image: url("../img/Project5.png");
}
.project6 {
	background-image: url("../img/Project6.png");
}
